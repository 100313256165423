import * as React from 'react';
import { graphql } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import FlashLayout from '../components/FlashLayout';
import { useExpectativasReporteTrimestralPost } from '../operations/queries';
import getCategoryColor from '../utils/getCategoryColor';
import { PostPdf as PostPdfProps } from '../models/PostPdf';
import options from '../utils/getRichTextOptions';
import PDFPreview from '../components/PdfPreview';
import { renderRichText } from "gatsby-source-contentful/rich-text"
import useIsClient from '../utils/isClient';
import { HeadApi } from '../components/HeadApi';

interface Props {
  data: {
    contentfulExpectativasReporteTrimestral: any;
  };
}

export const Head = ({data}) => {
  const post: PostPdfProps = useExpectativasReporteTrimestralPost(
    data.contentfulExpectativasReporteTrimestral
  );
  // console.log("[POST HEAD]", post);
  return(
    <HeadApi
      title={post.title}
      description={post.description}
      img={post.fluid?.gatsbyImageData.images.fallback.src}
    />
  );
};

export default function ExpectativasReporteTrimestral({
  data,
}: Props): JSX.Element {
  const color = getCategoryColor('rentaVariable');

  const post: PostPdfProps = useExpectativasReporteTrimestralPost(
    data.contentfulExpectativasReporteTrimestral
  );

  // console.log(post)

  const indices = 'https://airtable.com/embed/shrcrcg9KsdFUYma4';

  const isClient = useIsClient();

  if(isClient){

    return (
      <FlashLayout post={post} color={color}>
        <>
          <h2>{post.description}</h2>
  
          <Grid container style={{ marginTop: 50 }} spacing={4}>
            <PDFPreview pdf={post.pdf} />
  
            <Grid item xs={12}>
              {post.text && renderRichText(post.text, options)}
            </Grid>
          </Grid>
        </>
      </FlashLayout>
    );
  }else{
    return(<></>)
  }
}

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulExpectativasReporteTrimestral(slug: { eq: $slug }) {
      ...expectativasReporteTrimestral
    }
  }
`;
